import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://b223447f092eeca60229e92707e4b372@o266129.ingest.us.sentry.io/4507389019160576",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});